import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, newDatas, vm, search) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHead = [
          [
            { text: vm.$t('elearning_config.fiscal_year') + ' : ' + (vm.search.fiscal_year_id ? (vm.currentLocale === 'en' ? vm.searchResult.fiscal_year : vm.searchResult.fiscal_year_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_demandDIS.mill_name') + ' : ' + (vm.search.mill_info_id ? (vm.currentLocale === 'en' ? vm.searchResult.mill_name : vm.searchResult.mill_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_demandDIS.subzone_office_name') + ' : ' + (vm.search.subzone_office_id ? (vm.currentLocale === 'en' ? vm.searchResult.subzone_office_name : vm.searchResult.subzone_office_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        // const methodTableColumns = [
        //   {},
        //   {},
        //   {},
        //   {},
        //   {}
        // ]
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('bsri_report.cda_name'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('bsri_report.center'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('bsri_report.unit_no'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('bsri_report.farmer_no'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('bsri_demandDIS.total_farmer'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
            { text: 'check', colSpan: 8, style: 'th', alignment: 'center', bold: true },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            { text: vm.$t('globalTrans.total'), colSpan: 2, style: 'th', alignment: 'center', bold: true, rowSpan: 2 },
            {}
          ]
        ]
        const newArray = [
          {},
          {},
          {},
          {},
          {}
        ]
        vm.cultivationMethod.filter(methodItem => {
          const methodTableColumn = {
            text: vm.currentLocale === 'bn' ? methodItem.cultivationMethod_name_bn : methodItem.cultivationMethod_name,
            colSpan: 2,
            style: 'th',
            alignment: 'right'
          }
          newArray.push(methodTableColumn)
          newArray.push({})
        })
        newArray.push({}, {})
        allRows.push(newArray)
        const newUdArray = [
          {},
          {},
          {},
          {},
          {}
        ]
        vm.cultivationMethod.filter(methodItem => {
          newUdArray.push(
            {
              text: vm.$t('bsri_demandDIS.lnd_quantity'),
              style: 'th',
              alignment: 'center'
            },
            {
              text: vm.$t('bsri_demandDIS.amount_money'),
              style: 'th',
              alignment: 'center'
            }
          )
        })
        newUdArray.push(
          {
            text: vm.$t('bsri_demandDIS.lnd_quantity'),
            style: 'th',
            alignment: 'center'
          },
          {
            text: vm.$t('bsri_demandDIS.amount_money'),
            style: 'th',
            alignment: 'center'
          }
        )
        allRows.push(newUdArray)
        newDatas.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: '', alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.center_name_bn : info.center_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.unit_no : info.unit_no_bn, alignment: 'center', style: 'td' },
            { text: vm.$n(info.total_farmers), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.crop_name_bn : info.crop_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.sub_sidy_type_name_bn : info.sub_sidy_type_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.sub_sidy_name_bn : info.sub_sidy_name_bn, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.crop_name_bn : info.crop_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.sub_sidy_type_name_bn : info.sub_sidy_type_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.sub_sidy_name_bn : info.sub_sidy_name_bn, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.crop_name_bn : info.crop_name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.crop_name_bn : info.crop_name, alignment: 'center', style: 'td' },
            { text: vm.$n(info.total_quantity), alignment: 'center', style: 'td' },
            { text: vm.$n(info.total_amount), alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 3,
            widths: '*',
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 15 : 13,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 13,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          main_head: {
            fontSize: 9
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('sunzone-wise-consollidate-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
