<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('bsri_report.unit_wise_demand_report') }}</h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                text="text"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Fiscal Year" vid="mill_info_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="mill_info_id"
                            >
                            <template v-slot:label>
                              {{$t('bsri_demandDIS.mill_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.mill_info_id"
                                text="text"
                                :options="millInfoList"
                                id="mill_info_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Subzone office" vid="subzone_office_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="subzone_office_id"
                            >
                            <template v-slot:label>
                              {{$t('bsri_demandDIS.subzone_office_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.subzone_office_id"
                                text="text"
                                :options="subzoneOfficeList"
                                id="subzone_office_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Center" vid="center_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="center_id"
                            >
                            <template v-slot:label>
                              {{$t('bsri_demandDIS.center_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.center_id"
                                text="text"
                                :options="centerList"
                                id="center_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Unit" vid="unit_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="unit_id"
                            >
                            <template v-slot:label>
                              {{$t('bsri_demandDIS.unit') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.unit_id"
                                text="text"
                                :options="unitList"
                                id="unit_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('bsri_report.unit_wise_demand_report_list') }}</h4>
                        </template>
                        <!-- <template v-slot:headerAction>
                          <b-button variant="primary" @click="pdfExport" class="mr-2">
                            {{  $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template> -->
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                    <b-row>
                                        <b-col>
                                            <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="3">
                                                <template v-slot:projectNameSlot>
                                                {{ }}
                                                </template>
                                             <span style="text-decoration: underline;">{{ $t('bsri_report.unit_summary_report') }}</span>
                                            </list-report-head>
                                        </b-col>
                                    </b-row>
                                  <b-overlay :show="loading">
                                    <b-row>
                                        <b-col>
                                            <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                                <b-thead>
                                                    <b-tr>
                                                        <b-th rowspan="3" width="5%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                        <b-th rowspan="3" width="5%" class="text-center">{{ $t('bsri_demandDIS.nid_number') }}</b-th>
                                                        <b-th rowspan="3" width="5%" class="text-center">{{ $t('bsri_demandDIS.farmer_name') }}</b-th>
                                                        <b-th rowspan="3" width="5%" class="text-center">{{ $t('bsri_demandDIS.farmer_father_name') }}</b-th>
                                                        <b-th rowspan="3" width="5%" class="text-center">{{ $t('bsri_demandDIS.farmer_village') }}</b-th>
                                                        <b-th rowspan="3" width="5%" class="text-center">{{ $t('bsri_report.farmar_wallet') }}</b-th>
                                                        <b-th rowspan="" :colspan="(cultivationMethod.length * 2)" width="50%" class="text-center">{{ $t('bsri_config.cultivation_method_name') }}</b-th>
                                                        <b-th rowspan="2" colspan="2" width="15%" class="text-center">{{ $t('bsri_report.in_total') }}</b-th>
                                                    </b-tr>
                                                    <b-tr>
                                                        <th colspan="2" v-for="(item, indexc) in cultivationMethod" :key="indexc">
                                                        {{ $i18n.locale === 'bn' ?  item.cultivationMethod_name_bn :  item.cultivationMethod_name }}
                                                          ( {{ $t('bsri_demandDIS.acr_unit_first') }} {{ $n(item.per_acr_amount) }} {{ $t('bsri_demandDIS.acr_unit_second') }} )
                                                      </th>
                                                    </b-tr>
                                                    <b-tr>
                                                        <template v-for="(item2, index2) in cultivationMethod">
                                                          <template v-if="item2.value">
                                                            <th :key="index2+1">{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                                            <th :key="index2+2"> {{ $t('bsri_demandDIS.amount_money') }}</th>
                                                          </template>
                                                        </template>
                                                        <b-th class="text-center">{{ $t('bsri_report.amount_land') }}</b-th>
                                                        <b-th class="text-center">{{ $t('bsri_report.amount_money') }}</b-th>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                  <b-tr>
                                                    <b-td colspan="5"> <b>{{$t('bsri_demandDIS.subzone_office_name') }}</b> : {{ ($i18n.locale==='bn') ? getSubZone.text_bn : getSubZone.text_en }} </b-td>
                                                    <b-td colspan="3"> <b>{{$t('bsri_demandDIS.center_name') }} </b>: {{ ($i18n.locale==='bn') ? getCenter.text_bn : getCenter.text_en }} </b-td>
                                                    <b-td colspan="3"> <b>{{$t('bsri_demandDIS.unit') }} </b>: {{ ($i18n.locale==='bn') ? getUnit.text_bn : getUnit.text_en }} </b-td>
                                                  </b-tr>
                                                  <b-tr v-for="(info, index) in datas" :key="index">
                                                    <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                                                    <b-td class="text-center">{{ info.nid_no }}</b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name }}</b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.father_name_bn : info.father_name }}</b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.village_name_bn : info.village_name }}</b-td>
                                                    <b-td class="text-center">{{ info.mobile_no }}</b-td>
                                                    <template v-for="(item2, index3) in cultivationMethod">
                                                      <template v-if="item2.method_cultivation_id === info.cultivation_id">
                                                        <b-td class="text-center" :key="index3+1">{{ $n(info.total_land) }}</b-td>
                                                        <b-td class="text-center" :key="index3+2">{{ $n(info.total_land * info.per_acr_amount) }}</b-td>
                                                      </template>
                                                      <template v-else>
                                                        <b-td class="text-center" :key="index3+1">{{ $n(0) }}</b-td>
                                                        <b-td class="text-center" :key="index3+2">{{ $n(0) }}</b-td>
                                                      </template>
                                                    </template>
                                                    <b-td class="text-center">{{ $n(info.total_land) }}</b-td>
                                                    <b-td class="text-center">{{ $n(info.total_land * info.per_acr_amount) }}</b-td>
                                                  </b-tr>
                                              </b-tbody>
                                            </b-table-simple>
                                        </b-col>
                                    </b-row>
                                  <b-row>
                                  </b-row>
                                </b-overlay>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { unitwiseDemandReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)
export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        mill_info_id: 0,
        subzone_office_id: 0,
        center_id: 0,
        unit_id: 0
      },
      searchResult: {
          fiscal_year: '',
          fiscal_year_bn: '',
          season_name: '',
          season_name_bn: '',
          subsidy_type_name: '',
          subsidy_type_name_bn: '',
          subsidy_name: '',
          subsidy_name_bn: ''
      },
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      datas: [],
      headerExcelDefault: {
          orgName: null,
          orgNameBn: null,
          address: null,
          address_bn: null
      },
      loading: false,
      showData: false,
      subzoneOfficeList: [],
      centerList: [],
      unitList: [],
      cultivationMethod: []
    }
  },
  mounted () {
      core.index()
  },
  computed: {
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    cultivationMethodList: function () {
        return this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        return millInfoList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('dae_reports.subsidy_wise_allocation_report')
          headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('dae_reports.subsidy_wise_allocation_report')
          headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year_bn + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name_bn
        }
        return headerVal
    },
    excelData: function () {
      const listData = this.datas
      var serial = 0
      const listContractor = listData.map(keyItem => {
        serial++
          if (this.$i18n.locale === 'en') {
            return {
              'sl ': serial,
              'Subsidy Type': keyItem.sub_sidy_type_name,
              'Subsidy Name': keyItem.sub_sidy_name,
              'Crop Name': keyItem.crop_name,
              'District Name': keyItem.district_name,
              'Upazilla Name ': keyItem.upazilla_name,
              'Union Name': keyItem.union_name,
              'Agricultural material Type ': keyItem.mat_type_name,
              'Agriculture Material Name': keyItem.mat_name,
              'Agricultural material Quantity': keyItem.quantity
            }
          } else {
            return {
              'ক্রমিক সংখ্যা': this.$n(serial),
              'ভর্তুকির ধরণ': keyItem.sub_sidy_type_name_bn,
              'ভর্তুকির নাম': keyItem.sub_sidy_name_bn,
              'ফসলের নাম': keyItem.crop_name_bn,
              'জেলা ': keyItem.district_name_bn,
              'উপজেলা ': keyItem.upazilla_name_bn,
              'ইউনিয়ন ': keyItem.union_name_bn,
              'কৃষি উপকরণের ধরণ': keyItem.mat_type_name_bn,
              'পরিমাণ ': this.$n(keyItem.quantity)
            }
          }
      })
      return listContractor
    },
    getSubZone: function () {
      return this.$store.state.incentiveGrant.commonObj.subZoneList.find(item => item.value === this.search.subzone_office_id)
    },
    getCenter: function () {
      return this.$store.state.incentiveGrant.commonObj.centerList.find(item => item.value === this.search.center_id)
    },
    getUnit: function () {
      return this.$store.state.incentiveGrant.commonObj.unitList.find(item => item.value === this.search.unit_id)
    }
  },
  watch: {
    'search.mill_info_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
            this.subzoneOfficeList = this.getSubZoneList(newVal)
            this.centerList = []
            this.unitList = []
        }
    },
    'search.subzone_office_id': function (newVal) {
        this.centerList = this.getCenterList(newVal)
        this.unitList = []
    },
    'search.center_id': function (newVal) {
        this.unitList = this.getUnitList(newVal)
    }
  },
  methods: {
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item && item.subzone_office_id === subZoneId)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUnitList: function (centerId) {
        const unitList = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === centerId)
        return unitList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    pdfExport () {
        const reportTitle = this.$t('dae_reports.subsidy_wise_allocation_report')
        ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 8, reportTitle, this.datas, this)
    },
    async searchData () {
        this.cultivationMethod = this.customCultivationInfo()
        this.loading = true
        this.showData = true
        const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, unitwiseDemandReport, params).then(response => {
            if (response.success) {
            if (response.data) {
                this.datas = this.relationData(response.data)
            }
            } else {
            this.datas = []
            }
        })
        this.loading = false
    },
    relationData (data) {
      const villageList = this.$store.state.incentiveGrant.commonObj.villageList
      const listData = data.map(item => {
        const villageObject = villageList.find(villageItem => villageItem.value === item.village_id)
        const villageData = {
          village_name: villageObject !== undefined ? villageObject.text_en : ' ',
          village_name_bn: villageObject !== undefined ? villageObject.text_bn : ' '
        }
        return Object.assign({}, item, villageData)
      })
      return listData
    },
    customCultivationInfo () {
      const cultivationPolicyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.fiscal_year_id === this.search.fiscal_year_id)
      const listData = cultivationPolicyList.map(item => {
        const cultivationMethodObject = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(methodItem => methodItem.value === item.method_cultivation_id)
        const cultivationMethodData = {
          cultivationMethod_name: cultivationMethodObject !== undefined ? cultivationMethodObject.text_en : ' ',
          cultivationMethod_name_bn: cultivationMethodObject !== undefined ? cultivationMethodObject.text_bn : ' '
        }
        return Object.assign({}, item, cultivationMethodData)
        })

      return listData
    }
  }
}
</script>
<style>
th {
 border: 1px solid black!important;
 font-size:12px!important;
}
.notbold{
  font-weight:normal;
}
</style>
