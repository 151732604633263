export const subzoneWiseSummaryReport = 'bsri/reports/subzone-wise-summary'
export const centerWiseSummaryReport = 'bsri/reports/center-wise-summary'
export const millWiseSummaryReport = 'bsri/reports/mill-wise-summary'
export const subSidyAllocationReport = 'dae/reports/subsidy-wise-allocation-reports/list'
export const unitwiseDemandReport = 'bsri/reports/unit-wise-summary'
export const UnitWiseSummaryReport = 'bsri/reports/unit-wise-summary-report'
export const subzoneConsolidatedReport = 'bsri/reports/sub-zone-consolidated'
export const millWiseDisbursementReport = 'bsri/reports/mill-wise-disbursement'
export const subzoneWiseDisbursementReport = 'bsri/reports/subzone-wise-disbursement'
export const centerWiseDisbursementReport = 'bsri/reports/center-wise-disbursement'
export const unitWiseDisbursementReport = 'bsri/reports/unit-wise-disbursement'
